var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Producto"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo de producto",
      "label-for": "vi-tipo"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "vi-tipo",
      "size": "sm",
      "buttons": "",
      "options": _vm.tipos,
      "button-variant": "outline-primary"
    },
    model: {
      value: _vm.producto.tipo,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "tipo", $$v);
      },
      expression: "producto.tipo"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "IVA",
      "label-for": "vi-iva"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "vi-iva",
      "size": "sm",
      "buttons": "",
      "options": _vm.ivas,
      "button-variant": "outline-primary"
    },
    model: {
      value: _vm.producto.iva,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "iva", $$v);
      },
      expression: "producto.iva"
    }
  })], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Marca",
      "label-for": "vi-marca"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-marca",
      "label": "nombre",
      "reduce": function reduce(m) {
        return m.id;
      },
      "options": _vm.marcas,
      "loading": _vm.loadingMarcas
    },
    model: {
      value: _vm.producto.marca,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "marca", $$v);
      },
      expression: "producto.marca"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Nombre del producto",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ArchiveIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre de producto"
    },
    model: {
      value: _vm.producto.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "nombre", $$v);
      },
      expression: "producto.nombre"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Descripcion",
      "label-for": "vi-descripcion"
    }
  }, [_c('b-input-group', {
    attrs: {
      "BFormTextarea": ""
    }
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "FileTextIcon"
    }
  })], 1), _c('b-form-textarea', {
    attrs: {
      "id": "vi-descripcion",
      "rows": "3",
      "no-resize": ""
    },
    model: {
      value: _vm.producto.descripcion,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "descripcion", $$v);
      },
      expression: "producto.descripcion"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Peso [Gramos]",
      "label-for": "vi-peso"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "FeatherIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-peso",
      "type": "number"
    },
    model: {
      value: _vm.producto.peso,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "peso", $$v);
      },
      expression: "producto.peso"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Alto [Centimetros]",
      "label-for": "vi-altura"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CodepenIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-altura",
      "type": "number"
    },
    model: {
      value: _vm.producto.alto,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "alto", $$v);
      },
      expression: "producto.alto"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ancho [Centimetros]",
      "label-for": "vi-ancho"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CodepenIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-ancho",
      "type": "number"
    },
    model: {
      value: _vm.producto.ancho,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "ancho", $$v);
      },
      expression: "producto.ancho"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Profundidad [Centimetros]",
      "label-for": "vi-profundidad"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CodepenIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-profundidad",
      "type": "number"
    },
    model: {
      value: _vm.producto.profundidad,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "profundidad", $$v);
      },
      expression: "producto.profundidad"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Volumen",
      "label-for": "vi-volumen"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BoxIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-volumen",
      "type": "number"
    },
    model: {
      value: _vm.producto.volumen,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "volumen", $$v);
      },
      expression: "producto.volumen"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Unidades por caja",
      "label-for": "vi-unidad"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PackageIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-unidad",
      "type": "number"
    },
    model: {
      value: _vm.producto.unidades,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "unidades", $$v);
      },
      expression: "producto.unidades"
    }
  })], 1)], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "title": "Codigos",
      "border-variant": "secondary"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "SAP",
      "label-for": "vi-sap"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HashIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-sap",
      "type": "number"
    },
    model: {
      value: _vm.producto.codigoSAP,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "codigoSAP", $$v);
      },
      expression: "producto.codigoSAP"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Contifico",
      "label-for": "vi-contifico"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HashIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-contifico",
      "type": "text"
    },
    model: {
      value: _vm.producto.codigoContifico,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "codigoContifico", $$v);
      },
      expression: "producto.codigoContifico"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "SKU",
      "label-for": "vi-sku"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HashIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-sku",
      "type": "text"
    },
    model: {
      value: _vm.producto.codigoSKU,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "codigoSKU", $$v);
      },
      expression: "producto.codigoSKU"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Barras",
      "label-for": "vi-barras"
    }
  }, [_c('b-input-group', [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HashIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-barras",
      "type": "number"
    },
    model: {
      value: _vm.producto.codigoBarras,
      callback: function callback($$v) {
        _vm.$set(_vm.producto, "codigoBarras", $$v);
      },
      expression: "producto.codigoBarras"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-button', {
    staticClass: "my-2",
    attrs: {
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }