<template>
  <div>
    <feather-icon
      icon="MoreVerticalIcon"
      size="18"
      class="cursor-pointer"
      @click="onShow"
    />
    <b-sidebar
      right
      width="32em"
      shadow
      backdrop
      no-header
      v-model="show"
      bg-variant="white"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Modificar Canales</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->

        <div class="bodycnt">
          <b-button class="mb-2" variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Agregar Canal</span>
          </b-button>

          <div style="flex: 1">
            <b-list-group-item
              tag="li"
              :id="'canal-' + index"
              :key="index"
              v-for="(canal, index) in canales"
              class="item-cnt"
            >
              <!--  <b-avatar rounded size="30" variant="light-success">
                <feather-icon size="18" :icon="iconCanal(canal.tipo)" />
              </b-avatar> -->

              <v-select
                class="fl-2"
                label="nombre"
                v-model="canal.tipo"
                :reduce="(m) => m.id"
                :options="channels"
                :loading="loadingChannels"
                :clearable="false"
              />

              <b-form-input
                class="mx-1 fl-1"
                v-model="canal.precio"
                placeholder="Precio"
              />

              <b-button
                variant="outline-danger"
                class="mt-0"
                @click="removeItem(index)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
              </b-button>
            </b-list-group-item>
          </div>
        </div>
      </template>
      <template #footer>
        <b-row class="m-2">
          <b-col cols="12" md="6">
            <b-button
              block
              class="mr-2"
              type="submit"
              @click="saveData"
              variant="primary"
              :disabled="loading"
            >
              <b-spinner v-if="loading" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
          </b-col>
          <b-col cols="12" md="6">
            <b-button
              block
              variant="outline-secondary"
              @click="() => (show = false)"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  VBToggle,
  BSidebar,
  BSpinner,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BListGroupItem,
  BFormDatepicker,
  BFormTimepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BButton,
    BAvatar,
    VBToggle,
    BSidebar,
    BSpinner,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BListGroupItem,
    BFormDatepicker,
    BFormTimepicker,
  },
  props: {
    sCanales: {
      type: Array,
      required: true,
    },
    productoID: {
      type: Number,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    iconCanal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      error: "",
      loading: false,
      canales: [],
      channels: [],
      loadingChannels: true,
    };
  },
  beforeMount() {
    this.canales = JSON.parse(JSON.stringify(this.sCanales));
  },
  methods: {
    onShow() {
      this.show = true;
      if (this.channels.length === 0) this.getChannels();
    },
    getChannels() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetMulticanales",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.channels = res.data.canales;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loadingChannels = false;
        });
    },
    saveData() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/CreateUpdateProductChannel",
          body: JSON.stringify({
            canales: this.canales.filter((c) => c.precio !== ""),
            tokenSesion: getAuthToken(),
            productoID: this.productoID,
          }),
        })
        .then((res) => {
          this.show = false;
          this.loading = false;
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                text: "Se cambio correctamente los canales.",
                icon: "CheckIcon",
                title: "Exito",
                variant: "success",
              },
            });
            this.callback(
              this.canales.map((c) => {
                c.precio = parseFloat(`${c.precio}`);
                c.nombre = this.channels.find((cc) => c.tipo === cc.id).nombre;
                return c;
              })
            );
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrio un error al cambiar cambiar los canales [${e.message}].`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    repeateAgain() {
      if (!this.canales.some((s) => s.precio === "")) {
        this.canales.push({
          id: 0,
          tipo: null,
          precio: "",
        });
      }
    },
    removeItem(index) {
      this.canales.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.bodycnt {
  height: 85vh;
  padding: 2em;
  display: flex;
  flex-direction: column;
}
.item-cnt {
  display: flex;
  align-items: center;
}
.ldr {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fl-1 {
  flex: 1;
}
.fl-2 {
  flex: 2;
}
</style>
