<template>
  <b-card class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>Canales</b-card-title>
      <sidebar-canales
        :callback="updateCanales"
        :s-canales="canales"
        :icon-canal="iconCanal"
        :productoID="productData.id"
      />
    </b-card-header>

    <b-card-body>
      <div v-for="canal in canales" :key="canal.id" class="transaction-item">
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="30" variant="light-success">
              <feather-icon size="18" :icon="iconCanal(canal.tipo)" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="canal">
              {{ canal.nombre }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder">${{ canal.precio.toFixed(2) }}</div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardBody,
  BCardTitle,
  BMediaBody,
  BCardHeader,
  BMediaAside,
} from "bootstrap-vue";
import SidebarCanales from "./SidebarCanales.vue";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardBody,
    BMediaBody,
    BCardTitle,
    BCardHeader,
    BMediaAside,
    SidebarCanales,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      canales: [],
    };
  },
  beforeMount() {
    this.canales = this.productData.canales;
  },
  methods: {
    updateCanales(canales) {
      this.canales = canales;
    },
    iconCanal(s) {
      const variantIcon = {
        1: "SmartphoneIcon",
        2: "ChromeIcon",
        3: "MapPinIcon",
        7: "HomeIcon",
        8: "TruckIcon",
      };
      return variantIcon[s];
    },
  },
};
</script>
<style>
.canal {
  margin: 0;
  height: 2em;
  display: flex;
  align-items: center;
}
</style>