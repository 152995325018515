<template>
  <b-card title="Producto">
    <b-row>
      <!-- tipo -->
      <b-col cols="12" md="6">
        <b-form-group label="Tipo de producto" label-for="vi-tipo">
          <b-form-radio-group
            id="vi-tipo"
            size="sm"
            buttons
            v-model="producto.tipo"
            :options="tipos"
            button-variant="outline-primary"
          />
        </b-form-group>
      </b-col>

      <!-- tipo -->
      <b-col cols="12" md="6" class="text-right">
        <b-form-group label="IVA" label-for="vi-iva">
          <b-form-radio-group
            id="vi-iva"
            size="sm"
            buttons
            v-model="producto.iva"
            :options="ivas"
            button-variant="outline-primary"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Marca -->
    <b-form-group label="Marca" label-for="vi-marca">
      <v-select
        id="vi-marca"
        label="nombre"
        v-model="producto.marca"
        :reduce="(m) => m.id"
        :options="marcas"
        :loading="loadingMarcas"
      />
    </b-form-group>

    <!-- Nombre -->
    <b-form-group label="Nombre del producto" label-for="vi-nombre">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="ArchiveIcon" />
        </b-input-group-prepend>
        <b-form-input
          id="vi-nombre"
          v-model="producto.nombre"
          placeholder="Nombre de producto"
        />
      </b-input-group>
    </b-form-group>

    <!-- Descripcion -->
    <b-form-group label="Descripcion" label-for="vi-descripcion">
      <b-input-group BFormTextarea>
        <b-input-group-prepend is-text>
          <feather-icon icon="FileTextIcon" />
        </b-input-group-prepend>
        <b-form-textarea
          id="vi-descripcion"
          rows="3"
          v-model="producto.descripcion"
          no-resize
        />
      </b-input-group>
    </b-form-group>

    <b-row>
      <!-- Peso -->
      <b-col cols="12" md="6">
        <b-form-group label="Peso [Gramos]" label-for="vi-peso">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="FeatherIcon" />
            </b-input-group-prepend>
            <b-form-input id="vi-peso" type="number" v-model="producto.peso" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- Alto -->
      <b-col cols="12" md="6">
        <b-form-group label="Alto [Centimetros]" label-for="vi-altura">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="CodepenIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-altura"
              type="number"
              v-model="producto.alto"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- Ancho -->
      <b-col cols="12" md="6">
        <b-form-group label="Ancho [Centimetros]" label-for="vi-ancho">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="CodepenIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-ancho"
              type="number"
              v-model="producto.ancho"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- Profundidad -->
      <b-col cols="12" md="6">
        <b-form-group
          label="Profundidad [Centimetros]"
          label-for="vi-profundidad"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="CodepenIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-profundidad"
              type="number"
              v-model="producto.profundidad"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- Volumen -->
      <b-col cols="12" md="6">
        <b-form-group label="Volumen" label-for="vi-volumen">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="BoxIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-volumen"
              type="number"
              v-model="producto.volumen"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- Unidades -->
      <b-col cols="12" md="6">
        <b-form-group label="Unidades por caja" label-for="vi-unidad">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="PackageIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-unidad"
              type="number"
              v-model="producto.unidades"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card title="Codigos" border-variant="secondary">
      <b-row>
        <!-- Codigo SAP -->
        <b-col cols="12" md="6">
          <b-form-group label="SAP" label-for="vi-sap">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="HashIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-sap"
                type="number"
                v-model="producto.codigoSAP"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Codigo Contifico -->
        <b-col cols="12" md="6">
          <b-form-group label="Contifico" label-for="vi-contifico">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="HashIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-contifico"
                type="text"
                v-model="producto.codigoContifico"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Codigo SKU -->
        <b-col cols="12" md="6">
          <b-form-group label="SKU" label-for="vi-sku">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="HashIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-sku"
                type="text"
                v-model="producto.codigoSKU"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Codigo Barras -->
        <b-col cols="12" md="6">
          <b-form-group label="Barras" label-for="vi-barras">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="HashIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-barras"
                type="number"
                v-model="producto.codigoBarras"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      class="my-2"
      @click="saveData"
      variant="primary"
      :disabled="loading"
    >
      <b-spinner v-if="loading" small label="Loading..." />
      <div v-else>Guardar</div>
    </b-button>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BFormTextarea,
  BFormRadioGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    vSelect,
    BSpinner,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormRadioGroup,
    BInputGroupPrepend,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.producto = JSON.parse(JSON.stringify(this.productData));
  },
  data() {
    return {
      error: "",
      tipos: [
        { text: "Simple", value: "SIM" },
        { text: "Compuesto", value: "COP" },
      ],
      ivas: [
        { text: "0%", value: 0 },
        { text: process.env.VUE_APP_PORCENTAJE_IVA, value: parseInt(process.env.VUE_APP_ENTERO_IVA, 10) },
      ],
      marcas: [],
      bodegas: [],
      loading: false,
      producto: null,
      loadingMarcas: true,
    };
  },
  mounted() {
    this.getMarcas();
  },
  methods: {
    getMarcas() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/MarketMarcas",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.marcas = res.data.marcas;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loadingMarcas = false;
        });
    },
    saveData() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UpdateAllProductData",
          body: JSON.stringify({
            producto: this.producto,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                text: "Se guardo correctamente los cambios.",
                icon: "CheckIcon",
                title: "Exito",
                variant: "success",
              },
            });
            this.rebuildCache();
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrio un error al guardar los cambios [${e.message}].`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async rebuildCache() {
      const keys = [];
      await new Promise((resolve, reject) =>
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SectoresBodegas",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.bodegas = res.data.lsbodegas;
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          })
      );
      this.bodegas.forEach((b) => {
        keys.push({
          path: "/Productos/ConsultaProductoDetalle",
          body: { IDBodega: b.id, IDEmpresa: 2, IdProducto: this.producto.id },
        });
        this.productData.categorias.forEach((c) => {
          keys.push({
            path: "/Productos/ConsultaProductos",
            body: {
              Pagina: 1,
              Limite: 200,
              IDBodega: b.id,
              IDEmpresa: 2,
              IDCategoria: c,
              tokenSesion: getAuthToken(),
            },
          });
        });
      });
      keys.forEach((key) => {
        key.body.refreshCache = true;
        this.$http.post(this.$store.state.app.nodeMiddleware, key);
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

