<template>
  <div>
    <b-dropdown
      size="lg"
      variant="link"
      no-caret
      dropleft
      toggle-class="more-ico"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          class="cursor-pointer"
        />
      </template>
      <b-dropdown-item @click="onClickCrear">Crear descuento</b-dropdown-item>
      <b-dropdown-item @click="onClickVer">Ver descuentos </b-dropdown-item>
    </b-dropdown>
    <!-- modal Creacion -->
    <b-modal
      title="Crear Descuento"
      v-model="showModal"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-form-group>
        <b-form-radio-group
          buttons
          v-model="descuento.tipo"
          :options="tipos"
          button-variant="outline-primary"
        />
      </b-form-group>
      <b-form-group
        v-if="descuento.tipo === '01'"
        label="Porcentaje"
        label-for="v-descuento"
      >
        <b-input-group>
          <b-input-group-prepend is-text> % </b-input-group-prepend>
          <b-form-input
            id="v-descuento"
            type="number"
            v-model="descuento.descuento"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group
        v-if="descuento.tipo === '02'"
        label="Valor"
        label-for="v-descuento"
      >
        <b-input-group>
          <b-input-group-prepend is-text> $ </b-input-group-prepend>
          <b-form-input
            id="v-descuento"
            type="number"
            v-model="descuento.descuento"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group label="Fecha Inicio" label-for="v-fecha-ini">
        <b-form-datepicker
          id="v-fecha-ini"
          locale="es"
          v-model="descuento.fechaIni"
        />
      </b-form-group>
      <b-form-group label="Fecha Fin" label-for="v-fecha-fin">
        <b-form-datepicker
          id="v-fecha-fin"
          :min="descuento.fechaIni"
          locale="es"
          v-model="descuento.fechaFin"
        />
      </b-form-group>
      <b-form-group label="Hora Inicio" label-for="v-hora-ini">
        <b-form-timepicker
          id="v-hora-ini"
          v-model="descuento.horaIni"
          locale="en"
        />
      </b-form-group>

      <b-form-group label="Hora fin" label-for="v-hora-ini">
        <b-form-timepicker
          id="v-hora-fin"
          v-model="descuento.horaFin"
          locale="en"
        />
      </b-form-group>
      <b-form-group>
        <b-button
          class="mr-2"
          @click="() => (showModal = false)"
          variant="outline-secondary"
          >Cancelar</b-button
        ><b-button
          @click="createDiscount"
          variant="primary"
          :disabled="creando"
        >
          <b-spinner v-if="creando" small label="Loading..." />
          <div v-else>Guardar</div></b-button
        >
      </b-form-group>
    </b-modal>

    <!-- Sidebar listado -->
    <b-sidebar
      right
      width="700px"
      title="Lista de Descuentos"
      shadow
      backdrop
      v-model="showSidebar"
      bg-variant="white"
    >
      <div style="padding: 2em">
        <!-- table -->
        <vue-good-table
          :rows="descuentos"
          :columns="columns"
          :sort-options="{
            enabled: false,
          }"
          :search-options="{
            enabled: false,
          }"
          :select-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <b-button
                class="btn-icon"
                @click="confirmDelete($event, props.row)"
                variant="danger"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                  class="cursor-pointer"
                />
              </b-button>
            </span>
            <!-- Column: Estado -->
            <span v-else-if="props.column.field === 'estado'">
              <b-badge :variant="statusVariant(props.row)">
                {{ statusText(props.row) }}
              </b-badge>
            </span>

            <!-- Column: Fecha Inicio -->
            <span v-else-if="props.column.field === 'fechaIni'">
              <p class="mb-0">{{ formattedDate(props.row.fechaIni) }}</p>
              <p class="mb-0">{{ formattedHour(props.row.fechaIni) }}</p>
            </span>

            <!-- Column: Fecha Fin -->
            <span v-else-if="props.column.field === 'fechaFin'">
              <p class="mb-0">{{ formattedDate(props.row.fechaFin) }}</p>
              <p class="mb-0">{{ formattedHour(props.row.fechaFin) }}</p>
            </span>

            <!-- Column: Descuento -->
            <span v-else-if="props.column.field === 'descuento'">
              {{ descuentoText(props.row) }}
            </span>

            <!-- Column: Tipo -->
            <span v-else-if="props.column.field === 'tipo'">
              <b-badge :variant="tipoVariant(props.row.tipo)">
                {{ tipoText(props.row.tipo) }}
              </b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BBadge,
  BModal,
  BButton,
  BSidebar,
  BSpinner,
  BDropdown,
  BFormInput,
  BFormGroup,
  BPagination,
  BInputGroup,
  BDropdownItem,
  BFormTimepicker,
  BFormDatepicker,
  BFormRadioGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import moment from "moment";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BBadge,
    BModal,
    BButton,
    BSidebar,
    BSpinner,
    BDropdown,
    BFormInput,
    BFormGroup,
    BPagination,
    BInputGroup,
    VueGoodTable,
    BDropdownItem,
    BFormTimepicker,
    BFormDatepicker,
    BFormRadioGroup,
    BInputGroupPrepend,
  },
  props: {
    sDescuentos: {
      type: Array,
      required: true,
    },
    productoID: {
      type: Number,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      tipos: [
        { text: "Porcentaje", value: "01" },
        { text: "Monto", value: "02" },
        { text: "Free Delivery", value: "03" },
      ],
      creando: false,
      columns: [
        {
          label: "Tipo",
          field: "tipo",
        },
        {
          label: "Descuento",
          field: "descuento",
        },
        {
          label: "Estado",
          field: "estado",
        },
        {
          label: "Fecha Inicio",
          field: "fechaIni",
        },
        {
          label: "Fecha fin",
          field: "fechaFin",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      descuento: {},
      showModal: false,
      eliminando: 0,
      pageLength: 10,
      descuentos: [],
      showSidebar: false,
    };
  },
  beforeMount() {
    this.descuentos = JSON.parse(JSON.stringify(this.sDescuentos));
  },
  methods: {
    statusText(d) {
      if (d.estado) {
        if (
          moment().isSameOrAfter(moment(d.fechaIni)) &&
          moment().isSameOrBefore(moment(d.fechaFin))
        ) {
          return "Activo";
        } else {
          if (moment().isBefore(moment(d.fechaIni))) return "No iniciado";
          else return "Finalizado";
        }
      } else {
        return "Eliminado";
      }
    },
    statusVariant(d) {
      if (d.estado) {
        if (
          moment().isSameOrAfter(moment(d.fechaIni)) &&
          moment().isSameOrBefore(moment(d.fechaFin))
        ) {
          return "light-success";
        } else {
          if (moment().isBefore(moment(d.fechaIni))) return "light-info";
          else return "light-secondary";
        }
      } else {
        return "light-danger";
      }
    },
    descuentoText(descuento) {
      if (descuento.tipo === "01") return `${descuento.descuento}%`;
      else if (descuento.tipo === "02") return `$${descuento.descuento}`;
      else return `-`;
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(new Date(originalDate)).format("HH:mm a");
    },
    onClickCrear(event) {
      event.preventDefault();
      this.descuento = {
        id: 0,
        tipo: "01",
        estado: true,
        horaIni: null,
        horaFin: null,
        fechaIni: new Date(),
        fechaFin: null,
        descuento: 0,
        precioBajo: false,
      };
      this.showModal = true;
    },
    onClickVer(event) {
      event.preventDefault();
      this.showSidebar = true;
    },
    confirmDelete(event, discount) {
      event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Desea eliminar el descuento?.", {
          size: "sm",
          title: "Confirmacion",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) this.deleteDiscount(discount);
        });
    },
    createDiscount(event) {
      event.preventDefault();
      let fechaIni = moment(this.descuento.fechaIni);
      let fechaFin = moment(this.descuento.fechaFin);
      const horaIni = moment(this.descuento.horaIni, "HH:mm:ss");
      const horaFin = moment(this.descuento.horaFin, "HH:mm:ss");

      fechaIni.set({
        hour: horaIni.get("hour"),
        minute: horaIni.get("minute"),
        second: horaIni.get("second"),
        millisecond: 0,
      });

      fechaFin.set({
        hour: horaFin.get("hour"),
        minute: horaFin.get("minute"),
        second: horaFin.get("second"),
        millisecond: 0,
      });

      this.descuento.fechaIni = moment(fechaIni).format();
      this.descuento.fechaFin = moment(fechaFin).format();

      this.creando = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/CreateUpdateProductDiscount",
          body: JSON.stringify({
            descuento: this.descuento,
            tokenSesion: getAuthToken(),
            productoID: this.productoID,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            setTimeout(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Exito",
                  icon: "CheckIcon",
                  text: "Se creo el descuento correctamente",
                  variant: "success",
                },
              });
            }, 500);
            this.showModal = false;
            this.descuento.categoriaDesc= res.data.iDtemp
            this.callback(this.descuento);
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al crear el descuento [${e.message}].`,
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.creando = false;
        });
    },
    deleteDiscount(discount) {
      this.eliminando = discount.id;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/DeleteProductDiscount",
          body: JSON.stringify({
            descuento: discount,
            tokenSesion: getAuthToken(),
            productoID: this.productoID,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            setTimeout(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Exito",
                  icon: "CheckIcon",
                  text: "Se elimino el descuento correctamente",
                  variant: "success",
                },
              });
            }, 500);
            this.showSidebar = false;
            this.callback(discount.id,res.data.iDtemp);
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al eliminar el descuento [${e.message}].`,
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.eliminando = 0;
        });
    },
  },
  computed: {
    tipoText() {
      const tipos = {
        "01": "Porcentaje",
        "02": "Monto",
        "03": "Free Delivery",
      };
      return (s) => tipos[s];
    },
    tipoVariant() {
      const tipos = {
        "01": "light-info",
        "02": "light-success",
        "03": "light-danger",
      };
      return (s) => tipos[s];
    },
  },
};
</script>
<style lang="scss">
table.vgt-table {
  font-size: 13px !important;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
