var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('div', {
    staticClass: "loader"
  }, [_c('b-spinner')], 1) : _c('div', [_vm.productData ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('card-producto', {
    attrs: {
      "product-data": _vm.productData
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('card-imagenes', {
    attrs: {
      "product-data": _vm.productData,
      "callback": _vm.getProductData
    }
  }), _c('card-categorias', {
    attrs: {
      "product-data": _vm.productData
    }
  }), _c('card-canales', {
    attrs: {
      "product-data": _vm.productData
    }
  }), _c('card-descuentos', {
    attrs: {
      "product-data": _vm.productData,
      "callback": _vm.setCategory
    }
  }), _c('card-precios-bajos', {
    attrs: {
      "product-data": _vm.productData
    }
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }