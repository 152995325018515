var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": "Categorias"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-categorias",
      "label": "nombre",
      "multiple": "",
      "reduce": function reduce(c) {
        return c.id;
      },
      "loading": _vm.loading,
      "options": _vm.categorias,
      "getOptionLabel": function getOptionLabel(option) {
        return "".concat(option.id, " - ").concat(option.nombre);
      }
    },
    model: {
      value: _vm.productData.categorias,
      callback: function callback($$v) {
        _vm.$set(_vm.productData, "categorias", $$v);
      },
      expression: "productData.categorias"
    }
  }), _c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.saving
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.saving ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }