var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Imagenes")]), _c('sidebar-imagenes', {
    attrs: {
      "callback": _vm.updateImages,
      "s-imagenes": _vm.imagenes,
      "productoID": _vm.productData.id
    }
  })], 1), _c('b-card-body', [_vm.imagenes.length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "max-width": "250px"
    }
  }, [_c('b-carousel', {
    attrs: {
      "id": "carousel-example-generic",
      "controls": "",
      "img-width": "300",
      "img-height": "300",
      "indicators": "",
      "interval": 0
    }
  }, _vm._l(_vm.imagenes, function (imagen) {
    return _c('b-carousel-slide', {
      key: imagen,
      attrs: {
        "fluid": "",
        "rounded": "",
        "img-src": _vm.urlImagen(imagen, 600)
      }
    });
  }), 1)], 1)]) : _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v("El producto no tiene Imagenes")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }