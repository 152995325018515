<template>
  <div>
    <feather-icon
      icon="MoreVerticalIcon"
      size="18"
      class="cursor-pointer"
      @click="() => (show = true)"
    />
    <b-sidebar
      right
      width="32em"
      shadow
      backdrop
      no-header
      v-model="show"
      bg-variant="white"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Modificar Imagenes</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->

        <div class="bodycnt">
          <b-button class="mb-2" variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Agregar Imagen</span>
          </b-button>

          <div style="flex: 1">
            <draggable
              tag="ul"
              class="list-group cursor-move"
              v-model="imagenes"
            >
              <b-list-group-item
                v-for="(imagen, index) in imagenes"
                :id="'imagen-' + imagen.id"
                :key="imagen.id"
                tag="li"
                class="item-cnt"
              >
                <b-img
                  :src="urlResized(imagen.src)"
                  width="50"
                  height="50"
                  rounded
                />

                <div v-if="imagen.loading" class="ldr">
                  <b-spinner type="grow" label="Loading..." />
                </div>
                <b-form-file
                  v-else
                  class="mx-1"
                  accept=".jpg, .png"
                  @change="encodeImageFile($event, index)"
                  placeholder="Imagen"
                  browse-text="Seleccionar"
                />

                <b-button
                  variant="outline-danger"
                  class="mt-0"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-list-group-item>
            </draggable>
          </div>
        </div>
      </template>
      <template #footer>
        <b-row class="m-2">
          <b-col cols="12" md="6">
            <b-button
              block
              class="mr-2"
              type="submit"
              @click="saveData"
              variant="primary"
              :disabled="loading"
            >
              <b-spinner v-if="loading" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
          </b-col>
          <b-col cols="12" md="6">
            <b-button
              block
              variant="outline-secondary"
              @click="() => (show = false)"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  VBToggle,
  BSidebar,
  BSpinner,
  BFormFile,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BListGroupItem,
  BFormDatepicker,
  BFormTimepicker,
} from "bootstrap-vue";

import draggable from "vuedraggable";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    VBToggle,
    BSidebar,
    BSpinner,
    BFormFile,
    draggable,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BListGroupItem,
    BFormDatepicker,
    BFormTimepicker,
  },
  props: {
    sImagenes: {
      type: Array,
      required: true,
    },
    productoID: {
      type: Number,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      error: "",
      loading: false,
      imagenes: [],
      selected: null,
    };
  },
  beforeMount() {
    if(this.sImagenes){
      this.imagenes = this.sImagenes.map((imagen, index) => ({
        id: index,
        src: imagen,
        loading: false,
      }));
    }
  },
  methods: {
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/200x200" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
    encodeImageFile(element, index) {
      const uploadImage = this.uploadImage;
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        const fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          const srcData = fileLoadedEvent.target.result;
          uploadImage(srcData, index);
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },
    uploadImage(imagenSRC, index) {
      this.imagenes[index].loading = true;
      this.$http
        .post(this.$store.state.app.middlewareAWSURL, {
          path: "/Administracion/AWSUpload",
          body: JSON.stringify({
            files: imagenSRC,
            folder: 'Products',
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.imagenes[index].loading = false;
          if (res.data && res.data.length > 0) {
            const lastIndex = this.imagenes.length - 1;
            this.imagenes[index].id = this.imagenes[lastIndex].id + 1;
            this.imagenes[index].src = res.data[0].url;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.imagenes[index].loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrió un error al subir la imagen (${e.message}).`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
    saveData() {
      this.loading = true;
      const imagenes = this.imagenes
        .filter((i) => i.src !== "")
        .map((i) => i.src);
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UpdateProductImages",
          body: JSON.stringify({
            imagenes: imagenes,
            tokenSesion: getAuthToken(),
            productoID: this.productoID,
          }),
        })
        .then((res) => {
          this.show = false;
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                text: "Se cambio correctamente las imágenes.",
                icon: "CheckIcon",
                title: "Éxito",
                variant: "success",
              },
            });
            this.callback(imagenes);
            this.rebuildCache();
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrió un error al cambiar cambiar las imágenes [${e.message}].`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    repeateAgain() {
      if (!this.imagenes.some((s) => s.src === "")) {
        this.imagenes.push({
          id: this.imagenes.length,
          src: "",
          loading: false,
        });
      }
    },
    removeItem(index) {
      this.imagenes.splice(index, 1);
    },
    async rebuildCache() {
      const keys = [];
      await new Promise((resolve, reject) =>
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SectoresBodegas",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.bodegas = res.data.lsbodegas;
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          })
      );
      this.bodegas.forEach((b) => {
        keys.push({
          path: "/Productos/ConsultaProductoDetalle",
          body: { IDBodega: b.id, IDEmpresa: 2, IdProducto: this.producto.id },
        });
        this.productData.categorias.forEach((c) => {
          keys.push({
            path: "/Productos/ConsultaProductos",
            body: {
              Pagina: 1,
              Limite: 200,
              IDBodega: b.id,
              IDEmpresa: 2,
              IDCategoria: c,
              tokenSesion: getAuthToken(),
            },
          });
        });
      });
      keys.forEach((key) => {
        key.body.refreshCache = true;
        this.$http.post(this.$store.state.app.nodeMiddleware, key);
      });
    },
  },
};
</script>

<style>
.bodycnt {
  height: 85vh;
  padding: 2em;
  display: flex;
  flex-direction: column;
}
.item-cnt {
  display: flex;
  align-items: center;
}
.ldr {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
