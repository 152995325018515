<template>
  <b-card no-body class="card-developer-meetup">
    <b-card-header>
      <b-card-title>Descuentos</b-card-title>
      <options-descuentos
        v-if="descuentos"
        :callback="onchangeDiscounts"
        :productoID="productData.id"
        :s-descuentos="descuentos"
      />
    </b-card-header>
    <b-card-body v-if="currentDiscount">
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h2 class="mb-0">{{ discountText }}</h2>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25"> Descuento Activo </b-card-title>
          <b-card-text class="mb-0">
            {{ discountType }}
          </b-card-text>
        </div>
      </div>

      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="CalendarIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            De {{ formattedDate(currentDiscount.fechaIni) }} al
            {{ formattedDate(currentDiscount.fechaFin) }}
          </h6>
          <small
            >{{ formattedHour(currentDiscount.fechaIni) }} a
            {{ formattedHour(currentDiscount.fechaFin) }}</small
          >
        </b-media-body>
      </b-media>
    </b-card-body>
    <b-card-body v-else>
      <b-alert show variant="info">
        <div class="alert-body">
          <feather-icon class="mr-25" icon="CoffeeIcon" />
          <span class="ml-25">El producto no tiene Descuentos activos</span>
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAlert,
  BAvatar,
  BDropdown,
  BCardBody,
  BCardText,
  BMediaBody,
  BCardTitle,
  BCardHeader,
  BMediaAside,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";
import OptionsDescuentos from "./OptionsDescuentos.vue";
export default {
  components: {
    BCard,
    BMedia,
    BAlert,
    BAvatar,
    BDropdown,
    BCardBody,
    BCardText,
    BCardTitle,
    BMediaBody,
    BCardHeader,
    BMediaAside,
    BDropdownItem,
    OptionsDescuentos,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      descuentos: [],
      currentDiscount: null,
    };
  },
  beforeMount() {
    this.descuentos = this.productData.descuentos.filter((d) => !d.precioBajo);
  },
  mounted() {
    this.hasCurrentDiscounts();
  },
  methods: {
    onchangeDiscounts(discount,idCat) {
      const desc = JSON.parse(JSON.stringify(this.descuentos));
      if(!this.productData.categorias.includes(discount.categoriaDesc)){        
        this.callback(discount.categoriaDesc)
      }
      this.descuentos = null;
      this.$nextTick(() => {
        if (typeof discount === "number") {
          this.descuentos = desc.filter((d) => d.id !== discount);
          this.callback(idCat)
        } else {
          desc.push(discount);
          this.descuentos = desc;
        }
        this.hasCurrentDiscounts();
      });
    },
    hasCurrentDiscounts() {
      const actives = this.descuentos.filter(
        (d) =>
          d.estado &&
          moment().isSameOrAfter(moment(d.fechaIni)) &&
          moment().isSameOrBefore(moment(d.fechaFin))
      );
      this.currentDiscount = actives.sort(
        (a, b) => b.descuento - a.descuento
      )[0];
    },
    formattedDate(originalDate) {
      return moment(originalDate).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(originalDate).format("hh:mm a");
    },
  },
  computed: {
    discountText() {
      if (this.currentDiscount.tipo === "01")
        return `${this.currentDiscount.descuento}%`;
      else if (this.currentDiscount.tipo === "02")
        return `$${this.currentDiscount.descuento}`;
      else return `-`;
    },
    discountType() {
      if (this.currentDiscount.tipo === "01")
        return `Descuento de tipo Porcentaje`;
      if (this.currentDiscount.tipo === "02") return `Descuento de tipo Monto`;
      else return `Descuento de tipo Free Delivery`;
    },
  },
};
</script>
<style>
.more-ico {
  color: #6e6b7b;
  padding: 0;
}
</style>