<template>
  <b-card title="Categorias">
    <!-- Categorias -->
    <v-select
      id="vi-categorias"
      label="nombre"
      v-model="productData.categorias"
      multiple
      :reduce="(c) => c.id"
      :loading="loading"
      :options="categorias"
      :getOptionLabel="(option) => {
        return `${option.id} - ${option.nombre}`
      }"
    />

    <b-button
      block
      class="mt-1"
      @click="saveData"
      variant="primary"
      :disabled="saving"
    >
      <b-spinner v-if="saving" small label="Loading..." />
      <div v-else>Guardar</div>
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BButton, BSpinner } from "bootstrap-vue";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BButton,
    vSelect,
    BSpinner,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      bodegas: [],
      loading: true,
      categorias: [],
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/MarketCategories",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.categorias = res.data.categorias;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveData(event) {
      event.preventDefault();
      this.saving = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UpdateProductCategories",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
            producto: this.productData,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.$toast({
              component: ToastificationContent,
              props: {
                text: "Se guardo correctamente las categorias.",
                icon: "CheckIcon",
                title: "Exito",
                variant: "success",
              },
            });
            this.rebuildCache();
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: `Ocurrio un error al guardar las categorias (${e.message}).`,
              icon: "AlertTriangleIcon",
              title: "Error",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    async rebuildCache() {
      const keys = [];
      await new Promise((resolve, reject) =>
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SectoresBodegas",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.bodegas = res.data.lsbodegas;
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            reject(e.message);
          })
      );
      this.bodegas.forEach((b) => {
        this.productData.categorias.forEach((c) => {
          keys.push({
            path: "/Productos/ConsultaProductos",
            body: {
              Pagina: 1,
              Limite: 200,
              IDBodega: b.id,
              IDEmpresa: 2,
              IDCategoria: c,
              tokenSesion: getAuthToken(),
            },
          });
        });
      });
      keys.forEach((key) => {
        key.body.refreshCache = true;
        this.$http.post(this.$store.state.app.nodeMiddleware, key);
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>