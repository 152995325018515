var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-transaction",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Canales")]), _c('sidebar-canales', {
    attrs: {
      "callback": _vm.updateCanales,
      "s-canales": _vm.canales,
      "icon-canal": _vm.iconCanal,
      "productoID": _vm.productData.id
    }
  })], 1), _c('b-card-body', _vm._l(_vm.canales, function (canal) {
    return _c('div', {
      key: canal.id,
      staticClass: "transaction-item"
    }, [_c('b-media', {
      attrs: {
        "no-body": ""
      }
    }, [_c('b-media-aside', [_c('b-avatar', {
      attrs: {
        "rounded": "",
        "size": "30",
        "variant": "light-success"
      }
    }, [_c('feather-icon', {
      attrs: {
        "size": "18",
        "icon": _vm.iconCanal(canal.tipo)
      }
    })], 1)], 1), _c('b-media-body', [_c('h6', {
      staticClass: "canal"
    }, [_vm._v(" " + _vm._s(canal.nombre) + " ")])])], 1), _c('div', {
      staticClass: "font-weight-bolder"
    }, [_vm._v("$" + _vm._s(canal.precio.toFixed(2)))])], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }