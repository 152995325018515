<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Imagenes</b-card-title>
      <sidebar-imagenes
        :callback="updateImages"
        :s-imagenes="imagenes"
        :productoID="productData.id"
      />
    </b-card-header>
    <b-card-body>
      <div
        v-if="imagenes.length > 0"
        style="display: flex; justify-content: center"
      >
        <div style="max-width: 250px">
          <b-carousel
            id="carousel-example-generic"
            controls
            img-width="300"
            img-height="300"
            indicators
            :interval="0"
          >
            <b-carousel-slide
              v-for="imagen in imagenes"
              :key="imagen"
              fluid
              rounded
              :img-src="urlImagen(imagen, 600)"
            />
          </b-carousel>
        </div>
      </div>
      <b-alert v-else show variant="warning">
        <div class="alert-body">
          <feather-icon class="mr-25" icon="AlertTriangleIcon" />
          <span class="ml-25">El producto no tiene Imagenes</span>
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BAlert,
  BCardBody,
  BCarousel,
  BCardTitle,
  BCardHeader,
  BCarouselSlide,
} from "bootstrap-vue";
// import SidebarImagenes from "./SidebarImagenes.vue";
import SidebarImagenes from "./SideBarImagenes.vue";

export default {
  components: {
    BCard,
    BAlert,
    BCardBody,
    BCarousel,
    BCardTitle,
    BCardHeader,
    BCarouselSlide,
    SidebarImagenes,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      imagenes: [],
    };
  },
  beforeMount() {
    this.imagenes = this.productData.imagenes;
  },
  methods: {
    updateImages(imagenes) {
      this.imagenes = imagenes;
    },
    urlImagen(imagen, size) {
      try {
        const url = new URL(imagen);
        url.pathname = `/fit-in/${size}x${size}${url.pathname}`;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
  },
};
</script>