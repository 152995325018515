var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "MoreVerticalIcon",
      "size": "18"
    },
    on: {
      "click": function click() {
        return _vm.show = true;
      }
    }
  }), _c('b-sidebar', {
    attrs: {
      "right": "",
      "width": "32em",
      "shadow": "",
      "backdrop": "",
      "no-header": "",
      "bg-variant": "white"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "\n          d-flex\n          justify-content-between\n          align-items-center\n          content-sidebar-header\n          px-2\n          py-1\n        "
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v("Modificar Imagenes")]), _c('feather-icon', {
          staticClass: "ml-1 cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "size": "16"
          },
          on: {
            "click": hide
          }
        })], 1), _c('div', {
          staticClass: "bodycnt"
        }, [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.repeateAgain
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "PlusIcon"
          }
        }), _c('span', [_vm._v("Agregar Imagen")])], 1), _c('div', {
          staticStyle: {
            "flex": "1"
          }
        }, [_c('draggable', {
          staticClass: "list-group cursor-move",
          attrs: {
            "tag": "ul"
          },
          model: {
            value: _vm.imagenes,
            callback: function callback($$v) {
              _vm.imagenes = $$v;
            },
            expression: "imagenes"
          }
        }, _vm._l(_vm.imagenes, function (imagen, index) {
          return _c('b-list-group-item', {
            key: imagen.id,
            staticClass: "item-cnt",
            attrs: {
              "id": 'imagen-' + imagen.id,
              "tag": "li"
            }
          }, [_c('b-img', {
            attrs: {
              "src": _vm.urlResized(imagen.src),
              "width": "50",
              "height": "50",
              "rounded": ""
            }
          }), imagen.loading ? _c('div', {
            staticClass: "ldr"
          }, [_c('b-spinner', {
            attrs: {
              "type": "grow",
              "label": "Loading..."
            }
          })], 1) : _c('b-form-file', {
            staticClass: "mx-1",
            attrs: {
              "accept": ".jpg, .png",
              "placeholder": "Imagen",
              "browse-text": "Seleccionar"
            },
            on: {
              "change": function change($event) {
                return _vm.encodeImageFile($event, index);
              }
            }
          }), _c('b-button', {
            staticClass: "mt-0",
            attrs: {
              "variant": "outline-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.removeItem(index);
              }
            }
          }, [_c('feather-icon', {
            staticClass: "mr-25",
            attrs: {
              "icon": "XIcon"
            }
          })], 1)], 1);
        }), 1)], 1)], 1)];
      }
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "m-2"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "block": "",
            "type": "submit",
            "variant": "primary",
            "disabled": _vm.loading
          },
          on: {
            "click": _vm.saveData
          }
        }, [_vm.loading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "label": "Loading..."
          }
        }) : _c('div', [_vm._v("Guardar")])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "block": "",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click() {
              return _vm.show = false;
            }
          }
        }, [_vm._v(" Cancelar ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }