var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-developer-meetup",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Precios Bajos")]), _vm.descuentos ? _c('options-precios-bajos', {
    attrs: {
      "callback": _vm.onchangeDiscounts,
      "productoID": _vm.productData.id,
      "s-descuentos": _vm.descuentos
    }
  }) : _vm._e()], 1), _vm.currentDiscount ? _c('b-card-body', [_c('div', {
    staticClass: "meetup-header d-flex align-items-center"
  }, [_c('div', {
    staticClass: "meetup-day"
  }, [_c('h2', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.discountText))])]), _c('div', {
    staticClass: "my-auto"
  }, [_c('b-card-title', {
    staticClass: "mb-25"
  }, [_vm._v(" Descuento Activo ")])], 1)]), _c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "variant": "light-primary",
      "size": "34"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon",
      "size": "18"
    }
  })], 1)], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" De " + _vm._s(_vm.formattedDate(_vm.currentDiscount.fechaIni)) + " al " + _vm._s(_vm.formattedDate(_vm.currentDiscount.fechaFin)) + " ")]), _c('small', [_vm._v(_vm._s(_vm.formattedHour(_vm.currentDiscount.fechaIni)) + " a " + _vm._s(_vm.formattedHour(_vm.currentDiscount.fechaFin)))])])], 1)], 1) : _c('b-card-body', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "CoffeeIcon"
    }
  }), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v("El producto no tiene Precios Bajos activos")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }