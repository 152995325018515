<template>
  <div v-if="loading" class="loader">
    <b-spinner />
  </div>
  <div v-else>
    <b-row v-if="productData">
      <b-col cols="12" md="7">
        <card-producto :product-data="productData" />
      </b-col>

      <b-col cols="12" md="5">
        <card-imagenes :product-data="productData" :callback="getProductData" />
        <card-categorias :product-data="productData" />
        <card-canales :product-data="productData" />
        <card-descuentos :product-data="productData" :callback="setCategory" />
        <card-precios-bajos :product-data="productData" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BSpinner } from "bootstrap-vue";
import CardCanales from "./components/CardCanales.vue";
import CardImagenes from "./components/CardImagenes.vue";
import CardProducto from "./components/CardProducto.vue";
import CardCategorias from "./components/CardCategorias.vue";
import CardDescuentos from "./components/CardDescuentos.vue";
import CardPreciosBajos from "./components/CardPreciosBajos.vue";
import { getAuthToken } from "@/auth/utils";
export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    CardCanales,
    CardProducto,
    CardImagenes,
    CardCategorias,
    CardDescuentos,
    CardPreciosBajos,
  },

  data() {
    return {
      error: "",
      loading: true,
      productID: null,
      productData: null,
    };
  },
  beforeMount() {
    this.productID = parseInt(
      this.$route.path.replace("/market/producto/", "")
    );
    this.getProductData();
  },
  methods: {
    getProductData() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetAllProductData",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
            producto: {
              id: this.productID,
            },
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.productData = null;
            this.$nextTick(() => {
              this.productData = res.data.producto;
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setCategory(idCategory) {
      if (idCategory && Number(idCategory) > 0) {
        const index = this.productData.categorias.indexOf(idCategory);

        if (index === -1) {
          this.productData.categorias.push(idCategory);
        } else {
          this.productData.categorias = this.productData.categorias.filter(
            (cat) => cat !== idCategory
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
.loader {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
